import React, { Component } from 'react'
import Restaurants from '../Restaurants';
import Categories from '../Categories';
import Search from '../Search';
import Container from 'react-bootstrap/Container';
import border01 from '../../imgs/border01.png';
import border03 from '../../imgs/border03.png';
import logo01 from '../../imgs/logo03.png';
export class Home extends Component {

  componentDidMount() {
    const navbar = document.getElementById("myTopnav");
    navbar.classList.remove("header-shrink")
    navbar.classList.add("header-home")
  }

  componentWillUnmount() {
    const navbar = document.getElementById("myTopnav");
    navbar.classList.remove("header-home")
    navbar.classList.add("header-shrink")
  }

  render() {
    return (
      <div id={"test"}>
        <div className="parallax">
          <div style={overlay}>
            <Container style={center}>
              <img style={logo} src={logo01} />
              <h1 className="tagline">Table Reservations with Discounts</h1>
              <Search />
            </Container>
            <img src={border03} style={{ width: '100%', position: 'absolute', margin: 'auto', bottom:0, }} />
          </div>
        </div>
        <div style={{ background: '#eee' }}>
          <Container>
            <Categories />
          </Container>
        </div>
        <img src={border01} style={{ width: '100%' }} />
        <Container>
          <Restaurants />
        </Container>
      </div>
    )
  }
}

const overlay = {
  position: 'relative',
  width: '100%',
  backgroundColor: 'rgba(0,0,0,0.4)',
  height: '100vh',
}

const center = {
  textAlign: 'center',
  position: 'relative',
  right: 0,
  left: 0,
  top: '15%',
}

const logo = {
  background: '#fff',
  borderRadius: '10%',
  padding: '5px',
  width: '100px',
  height: '104px'
}
export default Home
