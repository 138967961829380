import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../.././imgs/logo01.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import border03 from '../../imgs/border03.png';
import badge_google from '../../imgs/badge_google.png';
import badge_apple from '../../imgs/badge_apple.png';
function Footer() {
    return (
        <React.Fragment>
            <img src={border03} style={{ width: '100%' }} />
            <div style={{ width: '100%', height: '150px', background: '#eee' }}></div>
            <footer>
                <div className="container" style={top}>
                    <div className="container" style={container}>
                        <div className="row">
                            <div className="col-md-5 col-sm-12 col-xs-12" style={{ textAlign: 'center', color: '#555' }}>
                                Want to receive the latest promotions from Fooderee?<br />
                                Subscribe to our e-newsletter!
                    </div>
                            <div className="col-md-7 col-sm-12 col-xs-12">
                                <Form inline>
                                    <FormControl className="col-md-6 col-sm-12 col-xs-12" type="email" placeholder="a@a.com" style={input} />
                                    <Button className="col-md-5 col-sm-12 col-xs-12" style={button} >Subscribe</Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row justify-content-md-center">
                        <div className="col-md-2 col-sm-12 col-xs-12" style={{ padding: '7px' }}>
                            <div className="">
                                STAY UPDATED!
                        <br />
                                <i className="fa fa-facebook-square fa-3x" aria-hidden="true"></i>
                                <i className="fa fa-instagram fa-3x" aria-hidden="true"></i>
                                <i className="fa fa-twitter-square fa-3x" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12 col-xs-12" style={{ padding: '7px' }}>
                            <div className="">
                                DOWNLOAD THE APP NOW!
                        <br />
                                <img src={badge_google} style={{ width: '150px' }} />
                                <img src={badge_apple} style={{ width: '150px' }} />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <li style={links}>Contact Us</li>
                        <li style={links}>FAQ</li>
                        <li style={links}>News & Press</li>
                        <li style={links}>Career</li>
                        <li style={links}>Terms & Conditions</li>
                        <li style={links}>Privacy Policy</li>
                    </div>
                    <p>Copyright 2017 All Right Reserved.</p>
                    <br/>
                </div>
            </footer>
        </React.Fragment>
    )
}
const top = {
    marginTop: '-70px',
}

const links = {
    display: 'inline',
    padding: '0px 5px'
}

const container = {
    background: '#fff',
    borderRadius: '25px',
    padding: '15px',
    boxShadow: '0px 0px 5px 0px rgba(000, 000, 000, .30)'
}


const input = {
    border: '2px solid #f32337',
    borderRadius: '10px',
    margin: '5px'
}

const button = {
    border: 'none',
    borderRadius: '10px',
    background: 'linear-gradient(to right, #f32337, #ff773b)',
    fontFamily: 'Quicksand',
    fontWeight: 'bold',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    margin: '5px'
}

export default Footer