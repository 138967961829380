import React, { Component } from 'react'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import noimage from '../imgs/noimage.jpg';
import test from '../imgs/test.jpg';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

export class Restaurants extends Component {
  componentDidMount() {
    setTimeout(window.scrollTo(0, 0), 100);
  }
  render() {
    return (
      <React.Fragment>
        <br /><br />
        <h2>Restaurants</h2>
        <Row>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={test} />
              <div >
                <Card.Title style={container}>
                  <p style={stars}>★★★★★</p>
                  <div style={title}>
                    <p style={main}>LoLa Cafe</p>
                    <p style={sub}>Tomas Morato | Filipino</p>
                  </div>
                </Card.Title>
                <Card.Footer style={slider}>
                  <Slider {...settings}>
                    <div className="slide">
                      <p>02:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>03:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>04:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>05:00PM</p>
                      <p>50%</p>
                    </div>
                  </Slider>
                </Card.Footer>
              </div>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={test} />
              <div >
                <Card.Title style={container}>
                  <p style={stars}>★★★★★</p>
                  <div style={title}>
                    <p style={main}>LoLa Cafe</p>
                    <p style={sub}>Tomas Morato | Filipino</p>
                  </div>
                </Card.Title>
                <Card.Footer style={slider}>
                  <Slider {...settings}>
                    <div className="slide">
                      <p>02:00PM</p>
                      <p>50%</p>
                    </div>
                  </Slider>
                </Card.Footer>
              </div>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={test} />
              <div >
                <Card.Title style={container}>
                  <p style={stars}>★★★★★</p>
                  <div style={title}>
                    <p style={main}>LoLa Cafe</p>
                    <p style={sub}>Tomas Morato | Filipino</p>
                  </div>
                </Card.Title>
                <Card.Footer style={slider}>
                  <Slider {...settings}>
                    <div className="slide">
                      <p>02:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>03:00PM</p>
                      <p>50%</p>
                    </div>
                  </Slider>
                </Card.Footer>
              </div>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={test} />
              <div >
                <Card.Title style={container}>
                  <p style={stars}>★★★★★</p>
                  <div style={title}>
                    <p style={main}>LoLa Cafe</p>
                    <p style={sub}>Tomas Morato | Filipino</p>
                  </div>
                </Card.Title>
                <Card.Footer style={slider}>
                  <Slider {...settings}>
                    <div className="slide">
                      <p>02:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>03:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>04:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>05:00PM</p>
                      <p>50%</p>
                    </div>
                  </Slider>
                </Card.Footer>
              </div>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={test} />
              <div >
                <Card.Title style={container}>
                  <p style={stars}>★★★★★</p>
                  <div style={title}>
                    <p style={main}>LoLa Cafe</p>
                    <p style={sub}>Tomas Morato | Filipino</p>
                  </div>
                </Card.Title>
                <Card.Footer style={slider}>
                  <Slider {...settings}>
                    <div className="slide">
                      <p>02:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>03:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>04:00PM</p>
                      <p>50%</p>
                    </div>
                  </Slider>
                </Card.Footer>
              </div>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={test} />
              <div >
                <Card.Title style={container}>
                  <p style={stars}>★★★★★</p>
                  <div style={title}>
                    <p style={main}>LoLa Cafe</p>
                    <p style={sub}>Tomas Morato | Filipino</p>
                  </div>
                </Card.Title>
                <Card.Footer style={slider}>
                  <Slider {...settings}>
                    <div className="slide">
                      <p>02:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>03:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>04:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>05:00PM</p>
                      <p>50%</p>
                    </div>
                  </Slider>
                </Card.Footer>
              </div>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={test} />
              <div >
                <Card.Title style={container}>
                  <p style={stars}>★★★★★</p>
                  <div style={title}>
                    <p style={main}>LoLa Cafe</p>
                    <p style={sub}>Tomas Morato | Filipino</p>
                  </div>
                </Card.Title>
                <Card.Footer style={slider}>
                  <Slider {...settings}>
                    <div className="slide">
                      <p>02:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>03:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>04:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>05:00PM</p>
                      <p>50%</p>
                    </div>
                  </Slider>
                </Card.Footer>
              </div>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={test} />
              <div >
                <Card.Title style={container}>
                  <p style={stars}>★★★★★</p>
                  <div style={title}>
                    <p style={main}>LoLa Cafe</p>
                    <p style={sub}>Tomas Morato | Filipino</p>
                  </div>
                </Card.Title>
                <Card.Footer style={slider}>
                  <Slider {...settings}>
                    <div className="slide">
                      <p>02:00PM</p>
                      <p>50%</p>
                    </div>
                    <div className="slide">
                      <p>03:00PM</p>
                      <p>50%</p>
                    </div>
                  </Slider>
                </Card.Footer>
              </div>
            </Card>
          </Col>
        </Row>
        <div style={{ textAlign: 'center', padding: '50px 0 30px 0' }}>
          <Link to="/restaurants"><Button style={button}>Show More ➤</Button></Link>
        </div>
      </React.Fragment>
    )
  }
}

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  // centerPadding: 0,
  pauseOnHover: true,
  // centerMode: true,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
};

const container = {
  margin: 0,
  marginTop: '-25px',
}

const slider = {
  background: '#f32337',
  padding: 0,
}

const stars = {
  margin: 'auto',
  minWidth: '100%',
  display: 'table',
  background: 'rgba(0, 0, 0, 0.7)',
  color: '#FFD503',
}

const title = {
  textAlign: 'center',
  padding: '5px',
  background: '#eee'
}

const main = {
  color: '#f32337',
  fontFamily: 'myriadpro-bold',
  letterSpacing: '1px',
  fontSize: '1.5rem',
  margin: 0,
  paddingBottom: '5px'
}

const sub = {
  color: '#212529',
  fontFamily: 'myriadpro-regular',
  fontSize: '1rem',
  margin: 0,
}

const colCustom = {
  padding: '3px',
}

const button = {
  border: 'none',
  borderRadius: '25px',
  background: 'linear-gradient(to right, #f32337, #ff773b)',
  fontFamily: 'Quicksand',
  fontWeight: 'bold',
  letterSpacing: '1px'
}

export default Restaurants
