import React from 'react'

function About() {
    return (
        <React.Fragment>
            <h1>About</h1>
            <p>asdadadadsadsadadadadadsadadada</p>
        </React.Fragment>
    )
}

export default About