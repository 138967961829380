import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import logo from '../.././imgs/logo01.png';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export class Header extends Component {
    componentDidMount = () => {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll = (e) => {
        const navbar = document.getElementById("myTopnav");
        console.log(window.scrollY);
        if (window.scrollY > 100) {
            navbar.classList.remove("header-expand")
            navbar.classList.add("header-shrink")
        } else {
            navbar.classList.remove("header-shrink")
            navbar.classList.add("header-expand")
        }

    };

    render() {
        return (
            <div>
                <header>
                    <div className="topnav header-shrink" id="myTopnav">
                        <Link className="logo" to="/"><img src={logo} alt="Fooderee Logo" /></Link>
                        <Link to="/restaurants">Restaurants</Link>
                        <Link to="/categories">Categories</Link>
                        <Link to="/search">Search</Link>
                        <Link to="/account">Account</Link>
                    </div>
                </header>
                <div className="tabs" style={tabs}>
                    <ul style={{ textAlign: 'center', margin: 0 }}>
                        <li className="tab" style={{ background: '#f32337' }}>
                            <Link to="/"><i className="fa fa-home "></i><p>Home</p></Link>
                        </li>
                        <li className="tab" style={{ background: '#ff773b' }}>
                            <Link to="/restaurants"><i className="fa fa-building "></i><p>Restaurants</p></Link>
                        </li>
                        <li className="tab" style={{ background: '#DC2C15' }}>
                            <Link to="/categories"><i className="fa fa-cutlery"></i><p>Categories</p></Link>
                        </li>
                        <li className="tab" style={{ background: '#ff773b' }}>
                            <Link to="/search"><i className="fa fa-search"></i><p>Search</p></Link>
                        </li>
                        <li className="tab" style={{ background: '#f32337' }}>
                            <Link to="/account"><i className="fa fa-user"></i><p>Account</p></Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}


const tabs = {
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    zIndex: 99,
    background: 'green',

}
const col = {
    textAlign: 'center',
    background: '#fff',
    padding: '10px'
}

const tab = {
    textAlign: 'center',
    borderRadius: 0,
    background: '#fff',
}
export default Header



