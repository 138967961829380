import React, { Component } from 'react'
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import noimage from '../imgs/noimage.jpg';
import test from '../imgs/test.jpg';
import { Link } from 'react-router-dom';

export class Categories extends Component {
  render() {
    return (
      <React.Fragment>
        <br/><br/>
        <h2>Categories</h2>
        <Row>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Link to="">
              <Card>
                <Card.Img variant="top" src={test} />
                <Card.Title style={title}>
                  <h4 style={main}>Filipino</h4>
                  <p style={sub}><span>20</span> restaurants</p>
                </Card.Title>
                <Card.Footer style={footer}><span style={{ color: 'red' }}>12</span> reservations</Card.Footer>
              </Card>
            </Link>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={noimage} />
              <Card.Title style={title}>
                <h4 style={main}>Japanese</h4>
                <p style={sub}><span>20</span> restaurants</p>
              </Card.Title>
              <Card.Footer style={footer}><span style={{ color: 'red' }}>12</span> reservations</Card.Footer>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={noimage} />
              <Card.Title style={title}>
                <h4 style={main}>Italian</h4>
                <p style={sub}><span>20</span> restaurants</p></Card.Title>
              <Card.Footer style={footer}><span style={{ color: 'red' }}>12</span> reservations</Card.Footer>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={noimage} />
              <Card.Title style={title}>
                <h4 style={main}>Wakandan</h4>
                <p style={sub}><span>20</span> restaurants</p>
              </Card.Title>
              <Card.Footer style={footer}><span style={{ color: 'red' }}>12</span> reservations</Card.Footer>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Link to="">
              <Card>
                <Card.Img variant="top" src={test} />
                <Card.Title style={title}>
                  <h4 style={main}>Filipino</h4>
                  <p style={sub}><span>20</span> restaurants</p>
                </Card.Title>
                <Card.Footer style={footer}><span style={{ color: 'red' }}>12</span> reservations</Card.Footer>
              </Card>
            </Link>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={noimage} />
              <Card.Title style={title}>
                <h4 style={main}>Japanese</h4>
                <p style={sub}><span>20</span> restaurants</p>
              </Card.Title>
              <Card.Footer style={footer}><span style={{ color: 'red' }}>12</span> reservations</Card.Footer>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={noimage} />
              <Card.Title style={title}>
                <h4 style={main}>Italian</h4>
                <p style={sub}><span>20</span> restaurants</p></Card.Title>
              <Card.Footer style={footer}><span style={{ color: 'red' }}>12</span> reservations</Card.Footer>
            </Card>
          </Col>
          <Col style={colCustom} xs={6} sm={6} md={4} lg={3} xl={3}>
            <Card>
              <Card.Img variant="top" src={noimage} />
              <Card.Title style={title}>
                <h4 style={main}>Wakandan</h4>
                <p style={sub}><span>20</span> restaurants</p>
              </Card.Title>
              <Card.Footer style={footer}><span style={{ color: 'red' }}>12</span> reservations</Card.Footer>
            </Card>
          </Col>
        </Row>
        <div style={{ textAlign: 'center', padding: '50px 0 30px 0' }}>
          <Link to="/categories"><Button style={button}>Show More ➤</Button></Link>
        </div>
      </React.Fragment>
    )
  }
}
const footer = {
  background: '#000',
  color: '#fff',
  padding: '2px 0',
  textAlign: 'center',
}

const title = {
  margin: 'auto',
  textAlign: 'center',
  position: 'absolute',
  minWidth: '100%',
  display: 'table',
  background: 'rgba(0, 0, 0, 0.7)',
  top: 0,
  bottom: 20,
}

const main = {
  color: '#fff',
  fontFamily: 'myriadpro-bold',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  fontSize: '1.3rem',
  margin: 0,
}

const sub = {
  color: '#fff',
  fontFamily: 'myriadpro-regular',
  fontSize: '1rem',
  margin: 0,
}

const colCustom = {
  padding: '3px',
}

const button = {
  border: 'none',
  borderRadius: '25px',
  background: 'linear-gradient(to right, #f32337, #ff773b)',
  fontFamily: 'Quicksand',
  paddin: '2px 5px',
  fontWeight: 'bold',
  letterSpacing: '1px'
}
export default Categories
