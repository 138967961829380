import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Container from 'react-bootstrap/Container';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import About from './components/pages/About';
import Search from './components/Search';
import Restaurants from './components/Restaurants';
import Categories from './components/Categories';
import Account from './components/pages/Account';
import Home from './components/pages/Home';
class App extends Component {

  SomeMethod () {
    const {pathname} = this.props.location;
    console.log('a');
  }

  render() {
    return (
      <Router onChange={this.SomeMethod}>
        <Header />
        <div className="wrapper">
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <div className="container">
            <Route path="/search" component={Search} />
          </div>
          <div className="container">
            <Route path="/categories" component={Categories} />
          </div>
          <div className="container">
            <Route path="/restaurants" component={Restaurants} />
          </div>
          <div className="container">
            <Route path="/account" component={Account} />
          </div>
        </div>
        <Footer />
      </Router>
    );
  }
}

export default App;
