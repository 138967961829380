import React, { Component } from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date()
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });

    }

    test() {
        document.getElementsByClassName('angelo')[0].readOnly = true;
    }
    render() {
        return (
            <React.Fragment>
                <Row className="searchBox">
                    <Col style={colCustom} xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Form.Control type="text" placeholder="Restaurant" />
                    </Col>
                    <Col style={colCustom} xs={6} sm={6} md={2} lg={2} xl={2}>
                        {/* <Form.Control type="text" placeholder="Date" /> */}
                        <DatePicker className="form-control angelo"
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            onInputClick={this.test} 
                            minDate={new Date()}
                        />
                    </Col>
                    <Col style={colCustom} xs={6} sm={6} md={2} lg={2} xl={2}>
                        <Form.Control as="select">
                            <option>City</option>
                            <option>...</option>
                        </Form.Control>
                    </Col>
                    <Col style={colCustom} xs={6} sm={6} md={2} lg={2} xl={2}>
                        <Form.Control as="select">
                            <option>Time</option>
                            <option>...</option>
                        </Form.Control>
                    </Col>
                    <Col style={colCustom} xs={6} sm={6} md={1} lg={1} xl={1}>
                        <Form.Control as="select">
                            <option>Seats</option>
                            <option>...</option>
                        </Form.Control>
                    </Col>
                    <Col style={colCustom} xs={12} sm={12} md={2} lg={2} xl={2}>
                        <Button style={submit} block>Search</Button>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}

const colCustom = {
    padding: '3px',
}

const submit = {
    background: '#F32337',
    border: 'none',
    textTransform: 'uppercase',
    fontFamily: 'Quicksand',
    fontWeight: 'bold',
}

export default Search
